import { httpClient } from "@/libs/http";
import { BARCODE_REMOVE_RECEIPT } from "@/libs/const";
import moment from "moment";

export default {
    props: {
        item: {
            type: Object,
            default: () => {
            }
        }
    },
    data: () => ({
        isLoading: false,
        quantity: null,
        reason: null,
        material: {},
        day: null,
        month: null,
        year: null,
        lotTracking: null,
        printNumber: 1,
        receiptQuantity: 1,
        basketCode: null,
        scannedBaskets: [],
        barcode: null,
        scannedBarcodes: [],
        scannedLocations: [],
        dialogConfirmRemove: false,
        itemWeight: null,
        itemUid: null,
        itemUidConfirm: null,
    }),
    computed: {
        scannedBasketTxt() {
            if (!this.scannedBaskets || this.scannedBaskets.length === 0) {
                return ''
            }
            const basketCodes = [...this.scannedBaskets].map(b => (b.code))
            return basketCodes.join('; ')
        },
        scannedLocationTxt() {
            if (!this.scannedLocations || this.scannedLocations.length === 0) {
                return ''
            }
            const locationCodes = [...this.scannedLocations].map(b => (b.code))
            return locationCodes.join('; ')
        },
        dayOptions() {
            if (!this.year || !this.month) {
                return []
            }
            const days = []
            for (let i = 1; i <= 31; i++) {
                const checkDay = moment(`${this.year}-${this.month}-${i}`)
                if (checkDay.isValid()) {
                    days.push({
                        value: i < 10 ? `0${i}` : `${i}`,
                        text: i < 10 ? `0${i}` : `${i}`
                    })
                }
            }
            return days
        },
        monthOptions() {
            const months = []
            for (let i = 1; i <= 12; i++) {
                months.push({
                    value: i < 10 ? `0${i}` : `${i}`,
                    text: i < 10 ? `0${i}` : `${i}`,
                })
            }
            return months
        },
        yearOptions() {
            const currentYear = moment().year()
            let years = []
            for (let i = currentYear; i < currentYear + 9; i++) {
                years.push({
                    value: `${i}`,
                    text: `${i}`
                })
            }
            return years
        },
        expiredDate() {
            let expired_date = null
            if (this.year) {
                if (!this.month) {
                    this.$vToastify.warning(this.$t('messages.unselected_month'));
                    return false
                }

                if (this.day) {
                    const checkDay = moment(`${this.year}-${this.month}-${this.day}`)
                    if (!checkDay.isValid()) {
                        this.$vToastify.warning(this.$t('messages.wrong_expired_date'));
                        return false
                    }
                    expired_date = checkDay.format('YYYY-MM-DD')
                }

                const checkDay = moment(`${this.year}-${this.month}`)
                if (!checkDay.isValid()) {
                    this.$vToastify.warning(this.$t('messages.wrong_expired_date'));
                    return false
                }
                expired_date = checkDay.endOf('month').format('YYYY-MM-DD')
            }
            return expired_date
        },
        removeCodes() {
            return [BARCODE_REMOVE_RECEIPT]
        },
        isShowBtnConfirm() {
            /* if (this.receiptQuantity > 0) {
                if ((!this.material.d_size && !this.material.new_d_size) || (!this.material.r_size && !this.material.new_r_size) || (!this.material.c_size && !this.material.new_c_size)) {
                    return false
                }
                if (!this.material.pre_weight && !this.material.new_pre_weight) {
                    return false
                }
            }
            if (this.receiptQuantity > 0 && this.scannedBaskets.length < 1 && this.scannedLocations.length < 1) {
                return false
            } */
            if (this.insertTracking && this.itemUid != this.itemUidConfirm) {
                return false
            }
            if (this.receiptQuantity > 0 && !this.itemWeight) {
                return false
            }
            if (this.receiptQuantity > 0 && this.material.process_exp_date && (!this.year || !this.month)) {
                return false
            }
            return true
        },
        labelBasketPosition() {
            if (this.scannedBaskets.length > 0) {
                return this.$t('labels.basket_code')
            } else if (this.scannedLocations.length > 0) {
                return this.$t('labels.location_code')
            } else {
                return this.$t('labels.basket_code_or_location')
            }
        }
    },
    mounted() {
        this.getMaterialById()
    },
    methods: {
        async printStamp() {
            if (this.insertTracking) {
                this.printStampAgain()
                return false
            }

            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                const { data } = await httpClient.post('/receipt-waiting-generate-material-uids', {
                    quantity: this.printNumber,
                    id_material: this.material.id,
                    id_material_receipt_detail: this.item.id,
                    lot_tracking: this.lotTracking,
                    expired_date: this.expiredDate,
                })
                this.insertTracking = data
                this.isLoading = false
                await this.getActiveStamp()
                await this.printStampAgain()
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        },
        async printStampAgain() {
            this.nextInputFocus(0)
            if (!this.isMobileAgent()) {
                await this.downloadExcelFile(`${process.env.VUE_APP_API_V2_URL}/generate-material-stamp`, {
                    id_material: this.material.id,
                    insert_tracking: this.insertTracking,
                }, `50x20_${this.insertTracking}.pdf`)
            } else {
                const { employee } = window.me || { employee: {} }
                const employeeFName = employee.name || employee.full_name
                const employeeNameArr = employeeFName.split(' ')
                const employeeName = employeeNameArr[employeeNameArr.length - 1]
                httpClient.post('/mobile-call-download-receipt-stamp', {
                    url: `${process.env.VUE_APP_API_V2_URL}/generate-material-stamp`,
                    id_material_warehouse: this.item.id_material_warehouse,
                    id_employee: employee.id,
                    params: JSON.stringify({
                        id_material: this.material.id,
                        insert_tracking: this.insertTracking,
                        employee_name: employeeName,
                        file_name: `50x20_${this.insertTracking}.pdf`
                    })
                })
            }
        },
        async getActiveStamp() {
            try {
                const { data } = await httpClient.post('/receipt-waiting-printed-by-material-and-employee', {
                    id_material: this.item.id_material,
                })
                if (data && data.count_id && data.count_id > 0 && data.insert_tracking) {
                    // this.$vToastify.warning(this.$t('messages.warning_stamp_printed', {quantity: data.count_id, sku: this.material.sku, min: data.min_uid, max: data.max_uid}));
                    this.itemUid = `${this.material.sku}@${data.min_uid}`
                    this.insertTracking = data.insert_tracking
                    this.lotTracking = data.lot_tracking
                    this.printNumber = data.count_id
                    if (data.expired_date) {
                        const month = moment(data.expired_date).month() + 1
                        const day = moment(data.expired_date).date()
                        this.year = `${moment(data.expired_date).year()}`
                        this.month = month < 10 ? `0${month}` : `${month}`
                        this.day = day < 10 ? `0${day}` : `${day}`
                    }
                }
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
            }
        },
        async scanBarcode() {
            const barcode = this.barcode
            this.barcode = null
            if (!barcode) {
                this.$vToastify.error(this.$t('messages.un_scanned_barcode'));
                this.$root.$emit('playErrorAudio')
                return false;
            }

            if (this.scannedBarcodes.length >= 100) {
                this.$vToastify.error(this.$t('messages.scan_max_100_barcode'));
                this.$root.$emit('playErrorAudio')
                return false;
            }

            const checkBarcode = this.scannedBarcodes.find(b => b === barcode)
            if (checkBarcode) {
                this.$vToastify.error(this.$t('messages.scanned_barcode'));
                this.$root.$emit('playErrorAudio')
                return false;
            }

            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                await httpClient.post('/receipt-check-special-barcode', {
                    barcode: barcode,
                    id_material: this.material.id
                })
                this.scannedBarcodes.unshift(barcode)
                this.isLoading = false
                this.$root.$emit('playSuccessAudio')
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        },
        async scanBasket() {
            if (!this.basketCode) {
                this.$vToastify.error(this.$t('messages.un_scanned_basket'));
                this.$root.$emit('playErrorAudio')
                return false;
            }

            if (this.basketCode === BARCODE_REMOVE_RECEIPT && (this.scannedBaskets.length > 0 || this.scannedLocations.length > 0)) {
                this.showConfirmRemove()
                this.basketCode = null
                return false
            }

            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            try {
                const { data } = await httpClient.post('/receipt-get-basket-or-location', {
                    code: this.basketCode,
                    id_material: this.material.id,
                    id_pos: this.item.id_pos,
                })
                this.basketCode = null
                this.isLoading = false

                if (data.basket) {
                    if (this.scannedLocations.length > 0) {
                        this.basketCode = null
                        this.$vToastify.error(this.$t('messages.scan_location_only'));
                        this.$root.$emit('playErrorAudio')
                        return false;
                    }
                    const basket = data.basket
                    const checkBasket = this.scannedBaskets.find(b => b.id == basket.id)
                    if (checkBasket) {
                        this.basketCode = null
                        this.$vToastify.error(this.$t('messages.scanned_basket'));
                        this.$root.$emit('playErrorAudio')
                        return false;
                    }

                    this.scannedBaskets.unshift({
                        id: basket.id,
                        code: basket.code
                    })
                    this.$root.$emit('playSuccessAudio')
                }
                if (data.location) {
                    if (this.scannedBaskets.length > 0) {
                        this.basketCode = null
                        this.$vToastify.error(this.$t('messages.scan_basket_only'));
                        this.$root.$emit('playErrorAudio')
                        return false;
                    }
                    const location = data.location
                    const checkLocation = this.scannedLocations.find(l => l.id == location.id)
                    if (checkLocation) {
                        this.basketCode = null
                        this.$vToastify.error(this.$t('messages.scanned_basket'));
                        this.$root.$emit('playErrorAudio')
                        return false;
                    }

                    this.scannedLocations.unshift({
                        id: location.id,
                        cell_id: location.cell_id
                    })
                    this.$root.$emit('playSuccessAudio')
                }
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
                this.basketCode = null
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        },
        cancel() {
            this.$emit('cancel')
        },

        async confirm() {
            let confirmMsg = this.$t('messages.are_you_sure_confirm_receipt', { quantity: this.receiptQuantity })
            if (
                (this.material.new_d_size && +this.material.new_d_size > 50) ||
                (this.material.new_r_size && +this.material.new_r_size > 50) ||
                (this.material.new_c_size && +this.material.new_c_size > 50) ||
                (
                    this.material.new_d_size && this.material.new_r_size && this.material.new_c_size &&
                    ((this.material.new_d_size * this.material.new_r_size * this.material.new_c_size) / 6) > 2000
                )
            ) {
                confirmMsg = this.$t('messages.are_you_sure_confirm_receipt_goods_big_size', {
                    quantity: this.receiptQuantity,
                    size: `${this.material.new_d_size}x${this.material.new_r_size}x${this.material.new_c_size}`,
                })
            }

            if (!confirm(confirmMsg)) {
                return false
            }

            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true

            const material = {
                ...this.material,
                d_size: this.material.d_size || 1,
                r_size: this.material.r_size || 1,
                c_size: this.material.c_size || 1,
                weight: this.material.weight || 1,
                pre_weight: this.material.pre_weight || 1,
                sub_barcode: this.material.sub_barcode || this.material.new_sub_barcode,
                comment: this.material.comment || this.material.new_comment,
            }
            try {
                await httpClient.post('/receipt-waiting-handle', {
                    material,
                    id: this.item.id,
                    receiptQuantity: this.receiptQuantity,
                    itemWeight: this.itemWeight,
                    scannedBaskets: this.scannedBaskets,
                    scannedLocations: this.scannedLocations,
                    insertTracking: this.insertTracking,
                    lotTracking: this.lotTracking,
                    expiredDate: this.expiredDate,
                })
                this.isLoading = false
                this.$root.$emit('playSuccessAudio')
                this.$emit('refreshData', false)
                this.$emit('cancel')
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
                this.isLoading = false
                this.$root.$emit('playErrorAudio')
            }
        },
        async getMaterialById() {
            try {
                const { data } = await httpClient.post('/material-detail', {
                    id: this.item.id_material,
                })
                this.material = { ...data }
                await this.getActiveStamp()
                this.isLoading = false
            } catch (e) {
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
            }
        },
        nextInputFocus(index) {
            if (index > 10) {
                return false
            }
            this.$nextTick(() => {
                if (this.$refs[`inputIndex_${index + 1}`]) {
                    this.$refs[`inputIndex_${index + 1}`].focus()
                } else {
                    this.nextInputFocus(index + 1)
                }
            })
        },

        showConfirmRemove() {
            this.dialogConfirmRemove = true
        },

        cancelConfirmRemove() {
            this.dialogConfirmRemove = false
            this.nextInputFocus(7)
        },

        confirmRemove(confirm_code) {
            const isBasket = this.isNumber(confirm_code)
            if (isBasket) {
                const checkBasket = this.scannedBaskets.find(b => b.code == confirm_code)
                if (!checkBasket) {
                    this.$vToastify.error(this.$t('messages.can_not_find_basket'));
                    this.$root.$emit('playErrorAudio')
                    return false;
                }
                this.scannedBaskets = [...this.scannedBaskets].filter(b => b.code != confirm_code)
            } else {
                const checkLocation = this.scannedLocations.find(b => b.cell_id == confirm_code)
                if (!checkLocation) {
                    this.$vToastify.error(this.$t('messages.can_not_find_location'));
                    this.$root.$emit('playErrorAudio')
                    return false;
                }
                this.scannedLocations = [...this.scannedLocations].filter(b => b.cell_id != confirm_code)
            }
        },
    }
}
